import { createSlice } from '@reduxjs/toolkit';

const escapedAndEndGameModalsActiveSlice = createSlice({
  name: 'escapedAndEndGameModalsActive',
  initialState: false,
  reducers: {
    setEscapedAndEndGameModalsActive: (state, action) => {
      state = action.payload;

      return state;
    }
  },
});

export const { setEscapedAndEndGameModalsActive} = escapedAndEndGameModalsActiveSlice.actions;
export default escapedAndEndGameModalsActiveSlice.reducer;
