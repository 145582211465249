import { createSlice } from '@reduxjs/toolkit';

const maxWinAndEndGameModalsActiveSlice = createSlice({
  name: 'maxWinAndEndGameModalsActive',
  initialState: false,
  reducers: {
    setMaxWinAndEndGameModalsActive: (state, action) => {
      state = action.payload;

      return state;
    }
  },
});

export const { setMaxWinAndEndGameModalsActive} = maxWinAndEndGameModalsActiveSlice.actions;
export default maxWinAndEndGameModalsActiveSlice.reducer;
