import { createSlice } from "@reduxjs/toolkit";

const popupMessageSlice = createSlice({
  name: "popupMessage",
  initialState: {
    message: "",
    delay: 5000
  },
  reducers: {
    setPopupMessage(state, action) {
      state = action.payload;

      return state;
    }
  }
});

export const { setPopupMessage } = popupMessageSlice.actions;
export default popupMessageSlice.reducer;
