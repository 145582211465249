import * as actions from '../api';
import apiClient from '../../services/axios';
import { setPopupMessage } from '../entities/popup-message-slice';
import { validateResponse } from '../../services/response-validators/validate-response';
import translate from '../../services/translation/lang';
//import { logout } from '../entities/auth';
//import Notify from '../../services/notification';


const api = ({dispatch}) => next => async action => {
  if (action.type !== actions.apiCallBegan.type) {

    return next(action);
  }

  if (action.type === actions.apiCallBegan.type) {
    dispatch({type: "spinner/setSpinner", payload: true});
  }

  next(action);

  const { url, method, data, onSuccess, onError } = action.payload;
  try {
    const response = await apiClient.request({
      url,
      method: method || 'POST',
      data
    });
    
    // Turn off spinner 
    dispatch({type: "spinner/setSpinner", payload: false});
    // General    
    dispatch(actions.apiCallSucess(response.data));
    //Specific
    /* console.log("RESPONSE", response.data);
    console.log("on URL", url); */
    if (onSuccess) {
        // Validate the response based on the URL
      if (!validateResponse(url, response.data)) {
        // If validation fails, the error message is handled inside the validator
        return;
      }
      dispatch({type: onSuccess, payload: response.data});
    }
    
  } catch (error) {
    console.log("ON ERROR ", error);
    console.log("on URL", url);
    // Turn off spinner 
    dispatch({type: "spinner/setSpinner", payload: false});
    // General 
    dispatch(actions.apiCallFailed(error));

    dispatch(setPopupMessage({
      message: translate("there_was_error"),
      delay: 5000
    }));

    // Specific
    if (onError) {
      return dispatch({type: onError, payload: error});
    }

    // if anauthenticated then logout
    // if ('status' in error) {
    //   if (error.status === 401 || error.status === 403) {
    //     //return dispatch(logout());
    //   }
    // }

    // Server error or to many attempts server response
    // if (error.status === 500 || error.status === 429) {
    //   //Notify.error('There was an error, please try again later');
    // } else {
    //   //Notify.error('There was an error, please try again later');
    // }
  }
}

export default api;
