import { createSlice } from "@reduxjs/toolkit";

const updatePlayerAvatarSlice = createSlice({
  name: "updatePlayerAvatar",
  initialState: null,
  reducers: {
    postUpdatePlayerAvatar(state, action) {
      state = action.payload;

      return state;
    }
  }
});

export default updatePlayerAvatarSlice.reducer;
