import { createSlice } from '@reduxjs/toolkit';

const bounsRoundsModalSlice = createSlice({
  name: 'bounsRoundsModal',
  initialState: {
    show:false,
    noOfRounds: 0,
    value: 0,
    currency: '',
    validUntil: null,
    alreadyShown: false
  },
  reducers: {
    setBounsRoundsModal: (state, action) => {
      state = action.payload;

      return state;
    },
    setAlreadyShownBonusModal: (state, action) => {
      state.alreadyShown = action.payload;

      return state;
    }
  },
});

export const { setBounsRoundsModal, setAlreadyShownBonusModal} = bounsRoundsModalSlice.actions;
export default bounsRoundsModalSlice.reducer;
