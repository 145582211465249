import { createSlice } from '@reduxjs/toolkit';

const webSocketStatusSlice = createSlice({
  name: 'webSocketStatus',
  initialState: 'disconnected',
  reducers: {
    setWebSocketStatus: (state, action) => {
      state = action.payload;

      return state;
    }
  },
});

export const { setWebSocketStatus } = webSocketStatusSlice.actions;
export default webSocketStatusSlice.reducer;
