import { createSlice } from "@reduxjs/toolkit";

const jackpotHistorySlice = createSlice({
  name: "jackpotHistory",
  initialState: {
    jackpotHistoryList: [],
  },
  reducers: {
    setJackpotHistoryList(state, action) {
      state.jackpotHistoryList = action.payload;
    },
  }
});

export const { setJackpotHistoryList } = jackpotHistorySlice.actions;
export default jackpotHistorySlice.reducer;
