import { setWebSocketStatus } from "../../store/entities/web-sock-status-slice";
import store from "../../store/configure-store";
import racconTransport from "../../services/raccoon-transport/raccoon-transport";
// import { shouldShowBonusModal } from "../../services/bonuses/bounus-modal";
// import { setBounsRoundsModal } from "../entities/modals/bonus-rounds-modal-slice";

const websocketMiddleware = () => {
  let socket = null;
  let reconnectAttempts = 0;
  let lastHeartbeat = Date.now();
  let heartbeatTimeout;

  const startHeartbeatCheck = () => {
    if (heartbeatTimeout) clearTimeout(heartbeatTimeout);

    heartbeatTimeout = setTimeout(() => {
      const now = Date.now();
      // Check if the last heartbeat was received more than 60 seconds ago
      if (now - lastHeartbeat > 60000) {
        console.warn('Heartbeat not received in time, reconnecting...');
        socket.close();
      }
    }, 60000); // Set the timeout to 60 seconds
  };

  const connect = (url) => {
    if (socket && (socket.readyState === WebSocket.OPEN || socket.readyState === WebSocket.CONNECTING)) {
      return;
    }

    // Dynamically determine ws/wss based on the page protocol
    const protocol = 'wss';
    const webSocketUrl = url.replace(/^http(s?):/, protocol + ':');

    socket = new WebSocket(webSocketUrl);

    socket.onopen = () => {
      console.log('WebSocket connected');
      store.dispatch(setWebSocketStatus('connected'));
      reconnectAttempts = 0; // Reset reconnection attempts
      startHeartbeatCheck(); // Start checking heartbeats
    };

    socket.onmessage = (event) => {
      let message = event.data;
      if (message === 'heartbeat') {
        // console.log('heartbeat received');
        // if (shouldShowBonusModal("eur")) {
        //   store.dispatch(setBounsRoundsModal({
        //     show:true,
        //     noOfRounds: 5,
        //     value: 20,
        //     currency:"EUR",
        //     validUntil: null,
        //     alreadyShown: false
        //   }))
        // }
        lastHeartbeat = Date.now(); // Update the last heartbeat time
        startHeartbeatCheck(); // Reset the heartbeat check timer
        //console.log('Received heartbeat');
      } else {
        try {
          const parsedData = JSON.parse(message);
          // Handle different events, e.g., balance or jackpot data
          if (parsedData.balance !== undefined) {
            racconTransport.balance(parsedData.balance)
          } else if (parsedData.jackpot_data !== undefined) {
            racconTransport.jackpot(parsedData.jackpot_data)
          } else if (parsedData?.message?.MessageTypeID !== undefined) {
            // free ie bonus tickets event
            if (parsedData.message.MessageTypeID === 24) {
              racconTransport.freeTickets(parsedData)
            }
          }
          else if (parsedData.bonus_expired !== undefined) {
            racconTransport.bonusExpired(parsedData.bonus_expired);
          }
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error occurred:', error);
      store.dispatch(setWebSocketStatus('error'));
      socket.close(); // Close the socket on error
    };

    socket.onclose = (event) => {
      console.log('WebSocket closed', event);
      store.dispatch(setWebSocketStatus('disconnected'));

      if (reconnectAttempts < 50) {
        reconnectAttempts++;
        console.log(`Reconnecting... attempt ${reconnectAttempts}`);
        setTimeout(() => connect(url), reconnectAttempts * 1000); // Exponential backoff for reconnect
      } else {
        store.dispatch(setWebSocketStatus('offline'));
        console.log('Max reconnection attempts reached.');
      }
    };
  };

  return (next) => (action) => {
    if (action.type === 'WEBSOCKET_CONNECT') {
      const { url } = action.payload;
      console.log(`Connecting to WebSocket at ${url}`);
      connect(url);
    }

    if (action.type === 'WEBSOCKET_SEND' && socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(action.payload));
    }

    return next(action);
  };
};

export default websocketMiddleware;
