import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from '../../services/axios';

export const getTakeStep = createAsyncThunk(
  'takeStep/postTakeStep',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiClient.request({
        url: process.env.REACT_APP_TAKE_STEP_ROUTE,
        method: 'POST',
        data
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const takeStepSlice = createSlice({
  name: "takeStep",
  initialState: {
    FieldContent:"",
    Status:0,
    Index: 1
  },
  reducers: {
    clearTakeStep(state) {
      state = {
        FieldContent:"",
        Status:0,
        Index: 1,
        loading:false
      };

      return state;
    },
    setTakeStepIndex(state,action) {
      state.Index = action.payload;

      return state;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTakeStep.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTakeStep.fulfilled, (state, action) => {
        state.loading = false;
        state.FieldContent = action.payload.FieldContent;
        state.Status = action.payload.Status;
        state.Index += 1; // Increment the Index by 1

       return state;
      })
      .addCase(getTakeStep.rejected, (state) => {
        state.loading = false;
      });
  }
    
});

export const { clearTakeStep, setTakeStepIndex } = takeStepSlice.actions;
export default takeStepSlice.reducer;
