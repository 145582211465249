import { createSlice } from '@reduxjs/toolkit';

const numberOfBetsSlice = createSlice({
  name: 'numberOfBets',
  initialState: 0,
  reducers: {
    setNumberOfBets: (state, action) => {
      state = action.payload;

      return state;
    }
  },
});

export const { setNumberOfBets } = numberOfBetsSlice.actions;
export default numberOfBetsSlice.reducer;
