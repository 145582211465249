import store from "../../store/configure-store";
import { setAlreadyShownBonusModal } from "../../store/entities/modals/bonus-rounds-modal-slice";
import { customizationParser } from "../helpers/customization-helper";

export const isCumulativeBonus = () => {
  let customConfig = customizationParser();
  if (customConfig?.raccoonrush?.bonusCumulative === true) {
    return true;
  }

  return false;
}

export const checkValidUntilFreeTicket = (freeTicketsExpirationTimestamp) => {
  if (!freeTicketsExpirationTimestamp) {
    store.dispatch(setAlreadyShownBonusModal(false));
  }
} 
