import store from "../../store/configure-store";
import { setPopupMessage } from "../../store/entities/popup-message-slice";
import { GENERIC_ERROR_MESSAGE } from "../helpers/generic-messages";


function hasRequiredKeys(obj) {
  return typeof obj === 'object' && 
         obj !== null && 
         'CompanyCurrencyData' in obj && 
         'Balance' in obj;
}
/**
 * validateBetResponse
 * 
 * @param {obj || null} response 
 */
export const validateLoginResponse = (response) =>  {
  try {
    if (hasRequiredKeys(response)) {
      return true;
    }
    store.dispatch(setPopupMessage({
      message: GENERIC_ERROR_MESSAGE,
      delay:  5000
    }));
    return false;
  } catch (error) {
    console.error("Error from validate Login Response: " ,error);
    store.dispatch(setPopupMessage({
      message: GENERIC_ERROR_MESSAGE,
      delay:  5000
    }));
    return false;
  }
}
