import { createSlice } from '@reduxjs/toolkit';

const bonusLabelSlice = createSlice({
  name: 'bonusLabel',
  initialState: false,
  reducers: {
    setBonusLabel: (state, action) => {
      state = action.payload;

      return state;
    }
  },
});

export const { setBonusLabel} = bonusLabelSlice.actions;
export default bonusLabelSlice.reducer;
