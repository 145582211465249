import React from "react";
import translate from "../services/translation/lang";
import raccoonRushLoading from "../img/racoon-rush-loading.png";
import byElbetLoading from "../img/by-elbet-loading.png";
import { useSelector } from "react-redux";

export default function Loading() {
  const popupMessage = useSelector(s => s.popupMessage);
  return (
    <div className="loading">
      <div className="loading-top">
        <img className="loading-image" src={raccoonRushLoading} alt="loading" />
        <div className="loading-text">
          {translate("loading")}
          <div className="loading-dots"></div>
        </div>
        {popupMessage.message && <p className="login-error">{popupMessage.message}</p>}
      </div>
      <div className="loading-bottom">
        <img className="loading-by-elbet" src={byElbetLoading} alt="by-elbet" />
      </div>
    </div>
  );
}
