import { urlParams } from '../get-url-params';
import { acceptedLang } from '../helpers/accepted-langs';
import {langArr} from './language';

let language =  acceptedLang(urlParams.get('language') || urlParams.get('lang')) ? urlParams.get('language') || urlParams.get('lang') : "en";

export const initLang = () => {
  document.querySelectorAll("[data-langName]").forEach((el) => {
    let langName = el.getAttribute('data-langName');
    el.innerHTML = langArr[language][langName];
  });
}

let translate = (word) => {
  word = word.toLowerCase()
  if (word in langArr[language]) {
    return langArr[language][word];
  }
  console.error('word not translated', word);
  return '';
}

export const languageReinit = (lang) => {
  translate = null;
  language = lang || 'en';
  translate = (word) => {
    if (word in langArr[language]) {
      return langArr[language][word];
    }
    console.error('word not translated', word);
    return '';
  }
}

export default translate;
