import { createSlice } from "@reduxjs/toolkit";

const betAmountSlice = createSlice({
  name: "betAmount",
  initialState: 0,
  reducers: {
    setBetAmount(state, action) {
      state = action.payload;

      return state;
    }
  }
});

export const { setBetAmount } = betAmountSlice.actions;
export default betAmountSlice.reducer;
