import { configureStore } from "@reduxjs/toolkit";
import rootReducer from './reducer';
import api from "./middleware/api";
import { persistReducer, persistStore,FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import websocketMiddleware from "./middleware/websocketMiddleware";

const persistConfig = {
  key: 'root-0.0.2',   
 // Key for the persisted state
  version: 1., // Version number for the persisted state
  storage, // Storage method
  whitelist: ['gameDifficulty','betAmount','hotKeys','winningStrike'
    ,'numberOfBets','bounsRoundsModal'] // Only persist data from yourSlice reducer
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware:   
 (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],   

      },
    }).concat(api,websocketMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
export const persistor = persistStore(store);
