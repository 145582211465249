import { createSlice } from "@reduxjs/toolkit";

const musicVolumeSlice = createSlice({
  name: "musicVolume",
  initialState: 0.085,
  reducers: {
    setMusicVolume(state, action) {
      state = action.payload;

      return state;
    }
  }
});

export const { setMusicVolume } = musicVolumeSlice.actions;
export default musicVolumeSlice.reducer;
