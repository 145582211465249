import { storageEnabled } from "../storage-enabled";
import LocalStorage from "./local-storage";
import MemoryStorage from "./memory-storage";

class Storage  {
  constructor(clientStorage) {
    this.clientStorage = clientStorage;
  }

  set(key,valueToStore) {
    try {
      this.clientStorage.set(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  }

  get(key) {
    try {
      return this.clientStorage.get(key) ? JSON.parse(this.clientStorage.get(key)) : null;
    } catch (error) {
      console.log(error);
    }
  }

  remove(key) {
    try {
      this.clientStorage.remove(key);
    } catch (error) {
      console.log(error);
    }
  }

  findLike (query) {
    return this.clientStorage.findLike(query);
  }
}

const storage = new Storage(storageEnabled() ? new LocalStorage() : new MemoryStorage());
export default storage;
