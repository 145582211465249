import store from "../../store/configure-store";
import { setBonusLabel } from "../../store/entities/bonus-label-slice";
import { setCumulativeBonusLabel } from "../../store/entities/cumulative-bonus-label-slice";
import { customizationParser } from "../helpers/customization-helper";

/**
 *  Sets proper bonus label according to the customization param
 */
export const setsProperBonusLabel = (value) => {
  let customConfig = customizationParser();

  if (customConfig?.raccoonrush?.bonusCumulative === true) {
    store.dispatch(setCumulativeBonusLabel(value));
  } else {
    store.dispatch(setBonusLabel(value));
  }
}
