import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from '../../services/axios';

export const startAutoBet = createAsyncThunk(
  'autoBet/postAutoBet',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiClient.request({
        url: process.env.REACT_APP_AUTO_BET_ROUTE,
        method: 'POST',
        data
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const autoBetSlice = createSlice({
  name: "autoBet",
  initialState: {
    Balance: 0,
    HitLane: 0,
    JP_Chance: 0,
    JackPot: 0,
    FieldContent:"",
    Status:0,
    TicketID: null
  },
  reducers: {
    clearAutoBet(state) {
      state = {
        Balance: 0,
        HitLane: 0,
        JP_Chance: 0,
        JackPot: 0,
        FieldContent:"",
        Status:0,
        TicketID: null
      };

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startAutoBet.pending, (state) => {
        state.loading = true;
      })
      .addCase(startAutoBet.fulfilled, (state, action) => {
        state = action.payload;
       
        return state;
      })
      .addCase(startAutoBet.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const { clearAutoBet } = autoBetSlice.actions;
export default autoBetSlice.reducer;
