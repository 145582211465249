import { createSlice } from '@reduxjs/toolkit';

const autoPlaySlice = createSlice({
  name: 'autoPlay',
  initialState: false,
  reducers: {
    toggleAutoPlay: (state, action) => {
      state = action.payload;
      return state;
    }
  },
});

export const { toggleAutoPlay } = autoPlaySlice.actions;
export default autoPlaySlice.reducer;
