import { createSlice } from "@reduxjs/toolkit";

const betHistorySlice = createSlice({
  name: "betHistory",
  initialState: {
    betHistoryList: [],
  },
  reducers: {
    setBetHistoryList(state, action) {
      state.betHistoryList = action.payload;
    },
  }
});

export const { setBetHistoryList } = betHistorySlice.actions;
export default betHistorySlice.reducer;
