import { createSlice } from '@reduxjs/toolkit';

const balanceSlice = createSlice({
  name: 'balance',
  initialState: {
    value: 0
  },
  reducers: {
    setBalanceValue: (state, action) => {
      state.value = action.payload;

      return state;
    }
  },
});

export const { setBalanceValue } = balanceSlice.actions;
export default balanceSlice.reducer;
