import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "login",
  initialState: null,
  reducers: {
    postLogin(state, action) {
      state = action.payload;

      return state;
    },
    clearActiveTicket(state) {
      state.ActiveTicket = null;

      return state;
    }
  }
});

export const { clearActiveTicket } = loginSlice.actions;
export default loginSlice.reducer;
