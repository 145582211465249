import { createSlice } from '@reduxjs/toolkit';

const autoPlayTargetLaneSlice = createSlice({
  name: 'autoPlayTargetLane',
  initialState: -1,
  reducers: {
    setAutoPlayTargetLane: (state, action) => {
      state = action.payload;
      return state;
    }
  },
});

export const { setAutoPlayTargetLane } = autoPlayTargetLaneSlice.actions;
export default autoPlayTargetLaneSlice.reducer;
