import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../../services/axios";

export const cancelBet = createAsyncThunk(
  'cancel/postCancel',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiClient.request({
        url: process.env.REACT_APP_CANCEL_ROUTE,
        method: 'POST',
        data
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const cancelSlice = createSlice({
  name: "cancel",
  initialState: null,
  reducers: {
    postCancel(state, action) {
      state = action.payload;

      return state;
    },
    clearCancel(state) {
      state = null;

      return state;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(cancelBet.fulfilled, (state, action) => {
        state =  action.payload;
        state.loading = false;

       return state;
      });
  }
});

export const { clearCancel } = cancelSlice.actions;
export default cancelSlice.reducer;
