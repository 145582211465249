import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from '../../services/axios';

export const placeFreeBet = createAsyncThunk(
  'freeBet/postFreeBet',
  async (data, { rejectWithValue }) => {
    try {
      const response = await apiClient.request({
        url: process.env.REACT_APP_FREE_BET_ROUTE,
        method: 'POST',
        data
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const freeBetSlice = createSlice({
  name: "freeBet",
  initialState: null,
  reducers: {
    clearFreeBet(state) {
      state = null;

      return state;
    },
    setActiveFreeBet(state, action) {
      state = action.payload;

      return state;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(placeFreeBet.pending, (state) => {
        //state.loading = true;
      })
      .addCase(placeFreeBet.fulfilled, (state, action) => {
        state = action.payload;
       
        return state;
      })
      .addCase(placeFreeBet.rejected, (state) => {
        //state.loading = false;
      });
  }
});

export const { clearFreeBet, setActiveFreeBet } = freeBetSlice.actions;
export default freeBetSlice.reducer;
