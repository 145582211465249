import { createSlice } from '@reduxjs/toolkit';

const remainingNumberOfBetsSlice = createSlice({
  name: 'remainingNumberOfBets',
  initialState: 0,
  reducers: {
    setRemainingNumberOfBets: (state, action) => {
      state = action.payload;

      return state;
    }
  },
});

export const { setRemainingNumberOfBets } = remainingNumberOfBetsSlice.actions;
export default remainingNumberOfBetsSlice.reducer;
