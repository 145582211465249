import { createSlice } from '@reduxjs/toolkit';

const bonusCumulativeSlice = createSlice({
  name: 'bonusCumulative',
  initialState: 0,
  reducers: {
    setBonusCumulative: (state, action) => {
      state = action.payload;

      return state;
    },
    increaseBonusCumulative: (state, action) => {
      state = state + action.payload;

      return state;
    }
  }
});

export const { setBonusCumulative, increaseBonusCumulative } = bonusCumulativeSlice.actions;
export default bonusCumulativeSlice.reducer;
