import { createSlice } from '@reduxjs/toolkit';

const escapedModalSlice = createSlice({
  name: 'escapedModal',
  initialState: {
    show:false,
    value: 0
  },
  reducers: {
    setEscapedModal: (state, action) => {
      state = action.payload;

      return state;
    }
  },
});

export const { setEscapedModal} = escapedModalSlice.actions;
export default escapedModalSlice.reducer;
